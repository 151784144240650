.blog {
    float: left;width: 65%;text-align: center;padding: 0 0 80px 0;
    &__Clear {float: left;width: 100%;margin-top: 60px;}
    &__More {padding:20px 30px;text-transform: initial;color: $brand3;vertical-align: middle;border: 1px solid $brand3;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    &__Nav {
      .news__More {display: inline-block;line-height:initial;height: auto;padding: 0px 30px;}
    }
    &__Cnt {float: left;width: 100%;}
    &__Box {@include span-columns(6);text-align: left;margin-bottom: 20px;
        &:nth-of-type(2n) {margin-right: 0;}
        &--img {float: left;width: 100%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
        &--cnt {float: left;width: 100%;}
        a {text-decoration: none;
            &:hover {
                p {color: $black;}
            }
        }
        h4 {float: left;width: 100%;color: $brand2;font-size: 24px;text-transform: uppercase;font-family: $second;margin-bottom: 0;}
        h6 {color: $brand2;margin: 0;}
        p {color: $brand2;font-size: 14px;}
        &--more {padding:10px 20px;text-transform: initial;color: $brand2;vertical-align: middle;border: 1px solid $brand2;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    }

  .paginationWrap {text-align: center;float: left;width: 100%;padding: 40px 0;
    span {float: left;width: 100%;text-align: center;}
  }
    
}
.sidebar {float: right;width: 30%;
  ul {padding: 0;margin: 0;}
  h2 {float: left;width: 100%;color: $brand;font-size: 24px;text-transform: uppercase;font-family: $second;margin: 0;margin-bottom: 10px;}
  .widget {float: left;width: 100%;margin-bottom: 20px;}
  .screen-reader-text {display: none;}
  input {width: 100%;height: 40px;padding: 0 10px;line-height: 40px;}
  input[type="submit"] {float: left;width: auto;margin-top: 10px;background: $white;border: 1px solid $black;padding: 0 30px;}
  #searchsubmit {
    margin-bottom: 10px;
}
  a {text-decoration: none;color: $brand2;
    &:hover {color: $black;}
  }
  li {list-style: none;margin-bottom: 5px;}
}
.awwards {
    float: left;width: 100%;text-align: center;padding: 0 0 80px 0;
    &__Clear {float: left;width: 100%;margin-top: 60px;}
    &__More {padding:20px 30px;text-transform: initial;color: $brand3;vertical-align: middle;border: 1px solid $brand3;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand3;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    &__Nav {
      .news__More {display: inline-block;line-height:initial;height: auto;padding: 0px 30px;}
    }
    &__Cnt {float: left;width: 100%;}
    &__Box {@include span-columns(6);text-align: left;margin-bottom: 70px;
        &:nth-of-type(2n) {margin-right: 0;}
        &--img {float: left;width: 100%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
        &--cnt {float: left;width: 100%;}
        a {text-decoration: none;
            &:hover {
                p {color: $black;}
            }
        }
        h4 {float: left;width: 100%;color: $brand2;font-size: 24px;text-transform: uppercase;font-family: $second;margin-bottom: 0;}
        h6 {color: $brand2;margin: 0;}
        p {color: $brand2;font-size: 14px;}
        &--more {padding:10px 20px;text-transform: initial;color: $brand2;vertical-align: middle;border: 1px solid $brand2;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;font-family: $second;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand2;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    }

  .paginationWrap {text-align: center;float: left;width: 100%;padding: 40px 0;
    span {float: left;width: 100%;text-align: center;}
  }
}