.siteFooter {float: left;width: 100%;background: #222;padding: 80px 0;border-top: 10px solid $white;padding-bottom: 0;
 
  &__Widget {width: 30%;
    &:nth-of-type(1) {float: left;}
    &:nth-of-type(3) {float: right;}
    &:nth-of-type(2) {margin-left: 5%;float: left;margin-right: 5%;}
    h2 {font-size: 32px;color: $white;}
    p {font-size: 16px;color: $white;font-weight: lighter;}
    a {font-size: 16px;color: $white;text-decoration: none;font-weight: lighter;}
    i {margin-right: 10px;width: 20px;}
    img {max-width: 150px;}
    li {list-style: none;display: block;}
    ul {padding: 0;margin: 0;}
  }
  &__Copy {float: left;background: #333;margin-top: 80px;width: 100%;padding: 20px 0;color: $white;
    span {float: left;}
    ul {list-style: none;float: right;padding: 0;margin: 0;
      li {display: inline-block;
        a {color: $white;height: 30px;width: 30px;border: 1px solid $white;text-decoration: none;display: inline-block;text-align: center;line-height: 27px;}
      }
    }
  }
}