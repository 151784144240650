.foogallery-default:after {
  content: '';
  display: block;
  clear: both;
}

.foogallery-default a {
    display: inline-block;
  overflow: hidden;
  vertical-align: top;
  max-width: 100%;
}

    .foogallery-default a img {
    border-radius: 0;
        display: block;
    -webkit-transition: none;
    transition: none;
      max-width: 100%;
      height: auto;
    }

/* Horizontal alignment */
.foogallery-default a img {
  vertical-align: top;
}

.foogallery-default.alignment-left {
  text-align: left;
}

.foogallery-default.alignment-center {
  text-align: center;
}

.foogallery-default.alignment-right {
  text-align: right;
}

/* Gutter widths */
.foogallery-default.spacing-width-5 a {
  margin-right: 5px;
  margin-bottom: 5px;
}

.foogallery-default.spacing-width-10 a {
  margin-right: 10px;
  margin-bottom: 10px;
}

.foogallery-default.spacing-width-15 a {
  margin-right: 15px;
  margin-bottom: 15px;
}

.foogallery-default.spacing-width-20 a {
  margin-right: 20px;
  margin-bottom: 20px;
}

.foogallery-default.spacing-width-25 a {
  margin-right: 25px;
  margin-bottom: 25px;
}

/* Border styles */
.foogallery-default.border-style-rounded a {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.foogallery-default.border-style-square-white a,
.foogallery-default.border-style-square-white a:hover {
  border: 5px solid #FFF !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.foogallery-default.border-style-circle-white a,
.foogallery-default.border-style-circle-white a:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 5px solid #FFF !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.foogallery-default.border-style-circle-black a,
.foogallery-default.border-style-circle-black a:hover {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 3px solid #000 !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.foogallery-default.border-style-square-black a,
.foogallery-default.border-style-square-black a:hover {
  border: 3px solid #000 !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
}

.foogallery-default.border-style-inset a {
  position: relative;
  max-width: 100%;
  content: "";
}

.foogallery-default.border-style-inset a:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 0 20px #000;
  -moz-box-shadow: inset 0 0 20px #000;
  -webkit-box-shadow: inset 0 0 20px #000;
}

/* Hover Effects */

.foogallery-default.hover-effect-zoom a,
.foogallery-default.hover-effect-zoom2 a,
.foogallery-default.hover-effect-zoom3 a,
.foogallery-default.hover-effect-plus a,
.foogallery-default.hover-effect-circle-plus a,
.foogallery-default.hover-effect-eye a,
.foogallery-default.hover-effect-tint {
  position: relative;
}

.foogallery-default.hover-effect-zoom a:before,
.foogallery-default.hover-effect-zoom2 a:before,
.foogallery-default.hover-effect-zoom3 a:before,
.foogallery-default.hover-effect-plus a:before,
.foogallery-default.hover-effect-circle-plus a:before,
.foogallery-default.hover-effect-eye a:before,
.foogallery-default.hover-effect-tint a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none no-repeat center center;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

.foogallery-default.hover-effect-zoom a:hover:before {
  background: rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom.png') no-repeat center center;
}

.foogallery-default.hover-effect-zoom2 a:hover:before {
  background:rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom2.png') no-repeat center center;
}

.foogallery-default.hover-effect-zoom3 a:hover:before {
  background:rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom3.png') no-repeat center center;
}

.foogallery-default.hover-effect-plus a:hover:before {
  background:rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/plus.png') no-repeat center center;
}

.foogallery-default.hover-effect-circle-plus a:hover:before {
  background:rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/circle-plus.png') no-repeat center center;
}

.foogallery-default.hover-effect-eye a:hover:before {
  background:rgba(0,0,0, 0.5) url('../../../../plugins/foogallery/extensions/default-templates/shared/img/eye.png') no-repeat center center;
}

/* @2x Images (Pixel Ratio of 1.25+) */
@media only screen and (-o-min-device-pixel-ratio: 5/4),
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and (min-device-pixel-ratio: 1.25),
only screen and (min-resolution: 1.25dppx) {

  .foogallery-default.hover-effect-zoom a:hover:before {
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom@2x.png');
    background-size: 32px 32px;
  }

  .foogallery-default.hover-effect-zoom2 a:hover:before {
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom2@2x.png');
    background-size: 32px 32px;
  }

  .foogallery-default.hover-effect-zoom3 a:hover:before {
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/zoom3@2x.png');
    background-size: 32px 32px;
  }

  .foogallery-default.hover-effect-plus a:hover:before{
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/plus@2x.png');
    background-size: 32px 32px;
  }

  .foogallery-default.hover-effect-circle-plus a:hover:before {
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/circle-plus@2x.png');
    background-size: 32px 32px;
  }

  .foogallery-default.hover-effect-eye a:hover:before {
    background-image: url('../../../../plugins/foogallery/extensions/default-templates/shared/img/eye@2x.png');
    background-size: 32px 32px;
  }
}

.foogallery-default.hover-effect-tint a:hover:before {
  background-image: none !important;
  background-color:rgba(0,0,0, 0.5);
}

.foogallery-default.hover-effect-color a img {
  background: none !important;
  /* For Webkit browsers */
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  /* Standard */
  filter: grayscale(100%);
  /* For IE 6 - 9 */
  filter: gray;
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
}

.foogallery-default.hover-effect-color a:hover img {
  -webkit-filter: none;
  filter: none;
  opacity: 1 !important;
}

.foogallery-default.hover-effect-color a:hover:before {
  background: none !important;
}

.foogallery-default.hover-effect-none a:before,
.foogallery-default.hover-effect-none a:before:hover {
  background: none !important;
}

/* Caption Styles */

.foogallery-default.hover-effect-caption a {
  position: relative;
}

.foogallery-default.hover-effect-caption a:before,
.foogallery-default.hover-effect-caption a:before:hover {
  background: none !important;
}

.foogallery-default .foogallery-caption {
  display: none;
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  z-index: 100;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  width: 100%;
  overflow: hidden;
}

.foogallery-default.hover-effect-caption .foogallery-caption-title,
.foogallery-default.hover-effect-caption .foogallery-caption-desc {
  color: #fff;
  font-size: 0.8em;
  padding: 0.25em;
}
.foogallery-default.hover-effect-caption .foogallery-caption-title {
  font-size: 1em;
}

.foogallery-default.hover-effect-caption.hover-caption-simple .foogallery-caption {
  bottom: 0;
  display: block;
  left: 0;
  text-align: center;
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.foogallery-default.hover-effect-caption.hover-caption-simple a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.foogallery-default.hover-effect-caption.hover-caption-simple-always .foogallery-caption {
  display: block;
  left: 0;
  bottom: 0;
  text-align: center;
}

.foogallery-default.hover-effect-caption.hover-caption-full-drop .foogallery-caption {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.foogallery-default.hover-effect-caption.hover-caption-full-drop a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.foogallery-default.hover-effect-caption.hover-caption-full-drop .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.foogallery-default.hover-effect-caption.hover-caption-full-fade .foogallery-caption {
  display: block;
  opacity: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.foogallery-default.hover-effect-caption.hover-caption-full-fade .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.foogallery-default.hover-effect-caption.hover-caption-full-fade a:hover .foogallery-caption {
  opacity: 1;
}

.foogallery-default.hover-effect-caption.hover-caption-push .foogallery-caption {
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.foogallery-default.hover-effect-caption.hover-caption-push a:hover .foogallery-caption {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.foogallery-default.hover-effect-caption.hover-caption-push img {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.foogallery-default.hover-effect-caption.hover-caption-push a:hover img {
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.foogallery-default.hover-effect-caption.hover-caption-push .foogallery-caption-inner {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Loading Styles */
.foogallery-default-loading {
  min-height: 11px;
  background: #fff url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA) no-repeat center center;
}

.foogallery-default-loading a,
.foogallery-default-loading img {
  display:none;
}

/* CUSTOM FORM */ 
.formWrapper {margin: 40px 0;}
.productFormOutbox {float: left;width: 100%;position: relative;margin-top: -500px;}
.productForm {position: absolute;background: $white;width: 665px;z-index: -10000000000;opacity: 0;top: -350px;padding: 50px;padding-bottom: 0;transition:opacity 500ms;
  label {font-size: 13px;font-weight: lighter;}
  input {margin-bottom: 10px !important;height: 30px;}
  input[type="submit"] {height: 50px;font-weight: lighter;
    &:hover {color: $white;}
  }
  textarea {margin-bottom: 10px;}
  p {margin: 0 !important; line-height: 1 !important;}
  &__Call {padding:10px 20px;text-transform: initial;color: $brand !important;vertical-align: middle;border: 1px solid $brand;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;text-decoration: none;font-family: $second;font-weight: lighter;cursor: pointer;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $brand;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $brand;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
  &__Close {position: absolute;float: right;right: 50px;transition:500ms;top: 30px;
    &:hover {color: $brand;transform:rotate(180deg) scale(1.3);cursor: pointer;}
  }
}
.activeForm {opacity: 1;z-index: 10000000000;}
.activeBody {position: relative;z-index: 1;float: left;width: 100%;transition:opacity 500ms;
  &:before {content: '';top: 0;bottom: 0;right: 0;left: 0;background: $black;opacity: 0.5;z-index: 1000000000;position: absolute;}
}
#wpsl-search-wrap .wpsl-input label {
    font-size: 15px;
}