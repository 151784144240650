.nav { position: relative; font-size: 16px;float: left;text-align: center; width: 88%;left: 0;z-index: 10;font-family: $second;
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: left; display: block;height: 90px;line-height: 90px;float: right;}
  li { display: inline-block; text-align: center; vertical-align: middle; position: relative;
    ul {position: absolute;top: 74px;line-height: 1 !important;min-width: 200px;height: 100%;display: block;float: left;left: 15px;visibility: hidden;opacity: 0;transform:translateY(-100%);transition:300ms;z-index: 1;
      
      li {display: block;text-align: left;background: $black;padding-left: 10px;position: relative;z-index: 1;
        &:first-of-type{padding-top: 10px;}
        &:last-of-type{padding-bottom: 10px;
          &:before {content: '';position: absolute;left: 0;bottom: 0px;height: 2px;right: 0;background: $brand;z-index: -1;transition:500ms;}
        }
        a {line-height: 2.5 !important;padding: 0 !important;font-size: 13px;}
        ul {position: absolute;top: 0;left: 300px;visibility: hidden;opacity: 0;
          li {background: alpha($black,0.8);
            a {text-transform: initial;}
          }
        }
      }
    }

      &:hover {
        ul {transform:translateY(0);opacity: 1;visibility: visible;}
      }
      ul {
        li {
          ul {visibility: hidden !important;opacity: 0 !important;}
      &:hover {
        ul {transform:translateY(0);opacity: 1 !important;visibility: visible !important;}
      }
        }
      }
  }
  a { color: $white;  font-size: 14px; text-decoration: none; line-height: 70px; margin: 0; display: block; position: relative;padding: 0 15px;z-index: 1;text-transform: uppercase;
    // &:before {content: '';position: absolute;left: 15px;right: 15px;bottom: 30px;height: 1px;background: $white;opacity: 0;transition:300ms;}
    &:hover {
      color: $brand;
      // &:before {opacity: 1;}
    }
  }
  li.current_page_item {
    a {
      color: $brand;
      // &:before {content: '';position: absolute;left: 15px;right: 15px;bottom: 30px;height: 1px;background: $white;opacity: 1;transition:300ms;}
    }
    ul {
      li {
        a {color: $white !important;
          &:hover {color: $brand !important;}
        }
        ul {
          li {
            a {color: $white !important;
          &:hover {color: $brand !important;}
            }
          }
        }
      }
    }
  } 
}
