.singleRepresentation {
  float: left;
  width: 100%;
  min-height: 400px;
  padding-bottom: 50px;
  overflow: hidden;
  padding: 50px 0; }
  .singleRepresentation h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #000000; }
  .singleRepresentation h4 {
    color: rgba(102, 102, 102, 0.4);
    font-size: 18px;
    font-weight: 400;
    width: 55%;
    margin: auto;
    text-align: center;
    margin-bottom: 50px; }
  .singleRepresentation__Box {
    width: 19%;
    float: left;
    height: 200px;
    margin-bottom: 1%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    background: $white;
border: 1px solid alpha($gray,0.5);
     }
    .singleRepresentation__Box a {
      float: left;
      width: 100%;
      background: #ffffff;
      text-align: center;
      background-size: 80% auto !important;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      height: 200px; }
  .singleRepresentation__Hover {
    position: fixed;
    display: none;
    z-index: 100000000000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }
    .singleRepresentation__Hover--inner {
      width: 600px;
      position: relative;
      margin: auto;
      top: 150px;
      background: #ffffff;
      padding: 20px; }
      .singleRepresentation__Hover--inner .closeIcon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer; }
      .singleRepresentation__Hover--inner p {
        color: #666666; font-size: 14px;}
      .singleRepresentation__Hover--inner a {
        height: auto;
        font-size: 14px;
        text-align: left;
        float: none;
        text-decoration: none;
        color: #d32731;
        z-index: 1000000000000;
        position: relative; }
        .singleRepresentation__Hover--inner a img {
          max-height: 40px; }
  .singleRepresentation .mask {
    position: fixed;
    display: none;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000000000; }        .defineHeight {min-height: 530px;}
    .activateLight {
  display: block !important; }
  .jaw_months {
    text-transform: capitalize !important;
    margin-left: 10px !important;
    font-size: 14px !important;
}