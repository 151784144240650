.siteHeader {position: fixed;float: left;width: 100%;z-index: 100000;height:90px;background: transparent;
  &__Logo {float: left;width: 12%;position: relative;z-index: 100;text-align: left;line-height: 90px;
    img {vertical-align: middle;max-height: 60px;}
  }
  &:before {content: '';position: absolute;left: 0;bottom: 0;height: 2px;right: 100%;background: $brand;z-index: -1;transition:500ms;}
  .logoWhite {position: absolute;left: 0;top: 15px;opacity: 1;transition:300ms;}
  .logoBlack {position: absolute;left: 0;top: 15px;opacity: 0;transition:300ms;}
}


.js-SiteHeader {
    transition: background .7s ease-in-out;
    &--is-Fixed {position: fixed;left: 0px;width: 100%;top: 0px;background: $white;height: 90px;
      &:before {right: 0;transition:700ms;}
      .nav {
        a {color: $black;}
        ul {
          li {
            ul {
              li {background: $white;}
            }
          }
        }
      }
  .logoWhite {opacity: 0;}
  .logoBlack {opacity: 1;}
    }
}
.fbx-count {
    display: none;
}
.rpwe-title {
font-weight:700 !important;
}