.postSingle {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;padding-bottom: 100px;
    
  }
  &__Top {width: 100%;margin: auto;text-align: left;padding: 70px 0;background: $brand;
      h2 {margin: 0;font-size: 48px;color: $white;font-weight: lighter;line-height: 1.2;position: relative;z-index: 1;text-transform: uppercase;max-width: 665px;margin: auto;
      }
      h6 {color: $white;max-width: 665px;margin: auto;}
      a {text-decoration: none;}
  }
  &__Cover {width: 100%;height: 450px;margin: auto;background-repeat: no-repeat;background-size: cover;background-position: center;position: relative;z-index: 1;
    &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.4;z-index: -1;}
  }
  &__Left {float: left;width: 49%;margin-right: 1%;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 16px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 16px; line-height: 1.6;color: $black;}
    li {font-size: 16px; line-height: 1.6;color: $black;}
    span {font-size: 16px; line-height: 1.6;color: $black;}
    a {font-size: 16px; line-height: 1.6;color: $brand;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
  &__Right {float: left;width: 49%;margin-left: 1%;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 16px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 16px; line-height: 1.6;color: $black;}
    li {font-size: 16px; line-height: 1.6;color: $black;}
    span {font-size: 16px; line-height: 1.6;color: $black;}
    a {font-size: 16px; line-height: 1.6;color: $brand;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
  &__Inner {max-width: 665px;margin: auto;margin-top: 70px;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
  &__InnerWithNo {max-width: 665px;margin: auto;margin-top: 70px;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    &--cnt {
      a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;}
    }
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
  &__Full {margin-top: 70px;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
}
.relatedPosts {padding-bottom: 150px;float: left;width: 100%;margin-top: 100px;
  &__Title {margin: 0;font-size: 36px;color: $black;font-weight: lighter;line-height: 1.2;max-width: 800px;margin: auto;margin-bottom: 90px;text-align: center;}
 &__Subtitle {margin: 0;font-size: 12px;letter-spacing: 2px;text-align: center;color: $brand;margin: 0;margin-bottom: 20px;text-transform: uppercase;font-weight: normal;}
  &__Inner {max-width: 600px;margin: auto;}
  &__Box {border-radius: 6px;padding: 50px 0px;background: $white;position: relative;z-index: 1;float: left;width: 100%;
        h2 {font-weight: lighter;margin: 0;color: $black;font-size: 28px;line-height: 1.3;margin-bottom: 20px;transition:300ms;
          &:hover {color: #004e99;}
        }
        h4 {text-transform: uppercase;font-size: 12px;line-height: 1.1;color: $brand;font-weight: bold;margin: 0;margin-bottom: 10px}
        p {font-size: 18px;color: $black;font-weight: lighter;margin: 0;line-height: 1.6;}
        &:before {left: 0px;right: 0;bottom: 0;height: 1px;background: #f0f0f0;position: absolute;content: '';z-index: -1;}
        a {text-decoration: none;transition:300ms;}
        &:last-of-type {
          &:before {display: none;}
        }
  }
}
.postSingleGray {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;
    
  }
  &__Top {width: 100%;margin: auto;text-align: left;padding: 70px 0;background: $brand3;
      h2 {margin: 0;font-size: 48px;color: $black;font-weight: lighter;line-height: 1.2;position: relative;z-index: 1;text-transform: uppercase;
      }
      a {text-decoration: none;}
  }
  &__locationMap {
    height: 950px;background-size: 100% auto;background-repeat: no-repeat;background-position: center center; float: left;width: 100%;background-color: $brand3;margin-top: 60px;
  }
  &__Cover {width: 100%;height: 450px;margin: auto;background-repeat: no-repeat;background-size: cover;background-position: center;border-bottom: 20px solid $brand;}
  &__Inner {
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;}
    h3 {font-size: 22px;font-weight: bold;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $black;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $brand;}
    }
    p {font-size: 18px; line-height: 1.6;color: $black;}
    li {font-size: 18px; line-height: 1.6;color: $black;}
    span {font-size: 18px; line-height: 1.6;color: $black;}
    a {font-size: 18px; line-height: 1.6;color: $brand;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $black;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $black;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $black;
      &:hover {background: $brand;color: $black;}
    }
  }
}
.locations {
    float: left;width: 100%;
    h2 {text-align: center;font-weight: lighter;font-size: 60px;margin: 0;}
   &__Nav {
    text-align: center;height: 50px;line-height: 50px;
    ul {padding: 0;margin: 0;list-style: none;}
    li {display: inline-block;color: $black;transition:300ms;position: relative;padding: 0 10px;z-index: 1;
      &:after {content: '';position: absolute;top: 10px;bottom: 10px;right: -5px;width: 1px;background: $gray;z-index: -1;}
      &:last-of-type {
        &:after {display: none;}
      }
      &:hover {cursor: pointer;color: $brand;}
    }
    li.locationCatActive {color: $brand;}
   }
&__Wrap {
    height: 300px;margin: auto;width: 100%;max-width: 860px;
}
&__Box {
    float: left;width: 100%;position: relative;
    .center {position: relative;min-height: 400px;padding-left: 100px;padding-right: 100px;padding: 50px 0;}
    a {text-decoration: none;}
    &:nth-of-type(2n) {background: $brand3;}
    &--img {float: left;width: 30%;height: 300px;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;
      a {float: left;width: 100%;height: 200px;}
    }
    &--content {width: 65%;margin-left: 5%;float: left;position: relative;
        h4 {font-size: 30px;color: $black;font-weight: bold;margin: 0;margin-top: -10px;margin-bottom: 10px;}
        p {color: $brand;margin: 2px 0;}
        .lastParagraph {margin-top: 20px;margin-bottom: 40px;}
        span {color: $black;font-weight: bold;margin-right: 3px;}
        &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;
        &:hover {
            color: $white;background: $brand;
        }
    }
    }
}
}
.partsSingle {float: left;width: 100%;background: $white;
  &__Content {overflow: hidden;position: relative;
    
  }
  &__Top {width: 100%;margin: auto;text-align: left;padding: 70px 0;background: $brand;
      h2 {margin: 0;font-size: 48px;color: $white;font-weight: lighter;line-height: 1.2;position: relative;z-index: 1;text-transform: uppercase;
      }
      a {text-decoration: none;}
  }
  &__Cover {width: 100%;height: 450px;margin: auto;background-repeat: no-repeat;background-size: cover;background-position: center;border-bottom: 20px solid $brand;}
  &__Inner {margin-top: 70px;
    h1 {font-size: 48px;line-height: 1.2;font-weight: lighter;color: $white;}
    h2 {font-size: 26px;line-height: 1.4;font-weight: lighter;color: $white;}
    h3 {font-size: 22px;font-weight: bold;color: $white;}
    h4, h5, h6 {font-size: 18px; line-height: 1.6;color: $white;}
    .wp-caption {width: auto !important;
      p {font-size: 12px;font-weight: lighter;color: $white;}
    }
    p {font-size: 18px; line-height: 1.6;color: $white;}
    li {font-size: 18px; line-height: 1.6;color: $white;}
    span {font-size: 18px; line-height: 1.6;color: $white;}
    a {font-size: 18px; line-height: 1.6;color: $white;text-decoration: none;}
    mark {background: #e7f5fe;}
    blockquote {background: #e7f5fe; font-weight: lighter; font-size: 16px; padding: 25px;color: #004e99;width: 100%;float: left;margin-left: 0;margin-right: 0;line-height: 1.8;margin-bottom: 60px;
      p {margin: 0;line-height: 1.8;color: #004e99;}
    }
    &--intro {margin-top: 60px;margin-bottom: 60px;
      p {font-size: 26px;line-height: 1.4;font-weight: lighter;}
      a {color: $brand;border-bottom: 1px dotted $brand;text-decoration: none;font-size: 26px;line-height: 1.4;font-weight: lighter;
        &:hover {border-bottom: 1px solid $brand;}
      }
    }
    input {width: 100%;border: 1px solid #dedede;padding: 0 10px;color: $white;font-weight: lighter;line-height: 46px;font-size: 14px;}
    textarea {width: 100%;max-width: 100%;min-width: 100%;border: 1px solid #dedede;padding: 0 10px; color: $white;font-weight: lighter;font-size: 14px;}
    input[type="submit"] {@include moreButton(#dedede, #dedede);color: $white;
      &:hover {background: $brand;color: $white;}
    }
  }
}
.topBox {
  float: left;width: 100%;
  &__Description {padding: 60px 0;float: left;width: 100%;background: $brand;
    &--title {background: $brand2;float: left;margin-top: -60px;padding: 10px 300px 10px 20px;
      h3 {color: $white;margin: 0;}
    }
    &--image {float: left;width: 30%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;margin-top: 40px;}
    &--content {float: left;width: 65%;margin-left: 5%;margin-top: 40px;
      p {margin-top: 0;color: $white;}
      &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;float: right;margin-top: 60px;
        &:hover {
            color: $white;background: $brand;
        }
      }
    }
  }
  &__Locations {
    background: $brand3;float: left;width: 100%;padding: 30px 0;
    ul {padding: 0;margin: 0;column-count:4;text-align: left;max-width: 920px;margin: auto;list-style: none;}
    li {padding-left: 1em; text-indent: -.7em;margin-bottom: 5px;-webkit-column-break-inside: avoid;page-break-inside: avoid;break-inside: avoid;
      &:before {content: "• ";color: $brand2;font-size: 18px;}
    }
    a {text-decoration: none;color: $brand;border-bottom: 1px solid transparent;font-size: 13px;
      &:hover {border-color: $brand2;}
    }
  }
  &__locationMap {
    height: 650px;background-size: auto 100%;background-repeat: no-repeat;background-position: center center; float: left;width: 100%;background-color: $brand3;
  }
}
.middleBox {
  float: left;width: 100%;
  &__Description {padding: 60px 0;float: left;width: 100%;background: $brand;
    &--title {background: $brand;float: left;margin-top: -60px;padding: 10px 300px 10px 20px;
      h3 {color: $white;margin: 0;}
    }
    &--image {float: left;width: 30%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;margin-top: 40px;}
    &--content {float: left;width: 65%;margin-left: 5%;margin-top: 40px;
      p {margin-top: 0;color: $white;}
      &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;float: right;margin-top: 60px;
        &:hover {
            color: $white;background: $brand;
        }
      }
    }
  }
  &__Locations {
    background: $white;float: left;width: 100%;padding: 30px 0;
    .center {max-width: 860px;}
    ul {padding: 0;margin: 0;text-align: left;float: left;width: 30%;margin-left: 1.5%;margin-right: 1.5%;list-style: none;
      h3 {text-transform: uppercase;font-weight: lighter;font-size: 28px;margin-bottom: 0;}
    }
    li {padding-left: 1em; text-indent: -.7em;margin-bottom: 5px;
      &:before {content: "• ";color: $brand2;font-size: 18px;}
    }
    a {text-decoration: none;color: $brand;border-bottom: 1px solid transparent;
      &:hover {border-color: $brand2;}
    }
    &--image {float: left;width: 100%;height: 180px;background-size: cover;background-repeat: no-repeat;background-position: center center;margin-top: 50px;}
  }
  &__locationMap {
    height: 650px;background-size: auto 100%;background-repeat: no-repeat;background-position: center center; float: left;width: 100%;background-color: $brand3;
  }
}
.bottomBox {
  float: left;width: 100%;
  &__Description {padding: 60px 0;float: left;width: 100%;background: $brand3;
    &--title {background: $gray;float: left;margin-top: -60px;padding: 10px 300px 10px 20px;
      h3 {color: $black;margin: 0;}
    }
    &--image {float: left;width: 30%;height: 200px;background-size: cover;background-repeat: no-repeat;background-position: center center;margin-top: 40px;}
    &--content {float: left;width: 65%;margin-left: 5%;margin-top: 40px;
      p {margin-top: 0;color: $black;}
      &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;float: right;margin-top: 60px;
        &:hover {
            color: $white;background: $brand;
        }
      }
    }
  }
  &__Locations {
    background: $brand3;float: left;width: 100%;padding: 30px 0;
    ul {padding: 0;margin: 0;column-count:4;text-align: center;list-style: none;}
    li {padding-left: 1em; text-indent: -.7em;margin-bottom: 5px;
      &:before {content: "• ";color: $brand2;font-size: 18px;}
    }
    a {text-decoration: none;color: $brand;border-bottom: 1px solid transparent;
      &:hover {border-color: $brand2;}
    }
  }
  &__locationMap {
    height: 650px;background-size: auto 100%;background-repeat: no-repeat;background-position: center center; float: left;width: 100%;background-color: $brand3;
  }
}
.footerBox {
  float: left;width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;position: relative;z-index: 1;
  &:before {position: absolute;content: '';top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.3;z-index: -1;}
  &__Box {float: left;width: 100%;
    .center {border-top: 1px solid $white;padding: 60px 0;max-width: 860px;}
    &:first-of-type {
      .center {border-top: 0;}
    }
    h2 {color: $white;}
    p {color: $white;}
    &--more{background: $brand2;color: $brand; text-transform: uppercase;padding-left: 40px;padding-right: 40px;height: 40px;line-height: 40px;font-weight: normal;border: 0;display: inline-block;text-decoration: none;transition:300ms;float: right;margin-top: 60px;
        &:hover {
            color: $white;background: $brand;
        }
      }
  }
  .addedbrand {background: $brand;
    .center {border-top: 0;}
  }
}
.justifyMe {text-align: justify !important;}
.googleMapContacts {float: left;width: 90%;margin-left: 5%;margin-right: 5%;height: 400px;margin-top: 5%;margin-bottom: 5%;
  .acf-map {float: left;width: 100%;height: 400px;}
}
.addMarginLeft {margin-left: 5px;}